@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
body {
  font-size: 20px;
}

.users {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.user {
  padding: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.user>div {
  margin-top: 1rem;
  color: #ccc;
}

@media (max-width: 768px) {
  .contract-signature {
    margin: 10px auto;
    border: 1px solid #113b61;
    width: 22rem;
    border: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    height: 23rem;
  }

  .signature {
    display: block;
    margin: 10px auto;
    border: 2px solid #113b61;
    width: 22rem;
    height: 10rem;
    padding: 10;
    border-radius: 9;
    background-color: rgb(243, 244, 244);
  }
}

@media (min-width: 768px) {
  .contract-signature {
    margin: 10px auto;
    border: 1px solid #113b61;
    width: 42rem;
    height: 30rem;
    border: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
  }

  .signature {
    display: block;
    margin: 10px auto;
    border: 2px solid #113b61;
    width: 32rem;
    height: 10rem;
    padding: 10;
    border-radius: 9;
    background-color: rgb(243, 244, 244);
  }
}

.bar-signature {
  display: flex;
  margin: 10px auto;
  width: 22rem;
  justify-content: space-around;
}

.title-signature {
  display: flex;
  margin: 10px auto;
  width: 22rem;
  justify-content: space-around;
  color: #113b61;
  font-family: Roboot;
  padding: 5px;
  border-radius: 9px;
}

@font-face {
  font-family: "Playball-Regular";
  src: url('/fonts/Playball-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.playballRegular {
  font-family: 'Playball-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "Whisper-Regular";
  src: url('/fonts/Whisper-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.whisperRegular {
  font-family: 'Whisper-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "AlexBrush-Regular";
  src: url('/fonts/AlexBrush-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.alexBrushRegular {
  font-family: 'AlexBrush-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "Parisienne-Regular";
  src: url('/fonts/Parisienne-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.parisienneRegular {
  font-family: 'Parisienne-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "Zocial";
  src: url('/fonts/Zocial.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.zocial {
  font-family: 'Zocial';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "Zeyada-Regular";
  src: url('/fonts/Zeyada-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.zeyadaRegular {
  font-family: 'Zeyada-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "Tangerine-Regular";
  src: url('/fonts/Tangerine-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.tangerineRegular {
  font-family: 'Tangerine-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "Satisfy-Regular";
  src: url('/fonts/Satisfy-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.satisfyRegular {
  font-family: 'Satisfy-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "KolkerBrush-Regular";
  src: url('/fonts/KolkerBrush-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.kolkerBrushRegular {
  font-family: 'KolkerBrush-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "JainiPurva-Regular";
  src: url('/fonts/JainiPurva-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.jainiPurvaRegular {
  font-family: 'JainiPurva-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "DancingScript-Regular";
  src: url('/fonts/DancingScript-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.dancingScriptRegular {
  font-family: 'DancingScript-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "Caveat-Regular";
  src: url('/fonts/Caveat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.caveatRegular {
  font-family: 'Caveat-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "BriemHand-Regular";
  src: url('/fonts/BriemHand-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.briemHandRegular {
  font-family: 'BriemHand-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "HerrVonMuellerhoff-Regular";
  src: url('/fonts/HerrVonMuellerhoff-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.HerrVonMuellerhoffRegular {
  font-family: 'HerrVonMuellerhoff-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "MeieScript-Regular";
  src: url('/fonts/MeieScript-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.meieScriptRegular {
  font-family: 'MeieScript-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "Stalemate-Regular";
  src: url('/fonts/Stalemate-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.stalemateRegular {
  font-family: 'Stalemate-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "PinyonScript-Regular";
  src: url('/fonts/PinyonScript-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.pinyonScriptRegular {
  font-family: 'PinyonScript-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "GreatVibes-Regular";
  src: url('/fonts/GreatVibes-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.greatVibesRegular {
  font-family: 'GreatVibes-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "MrsSaintDelafield-Regular";
  src: url('/fonts/MrsSaintDelafield-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.mrsSaintDelafieldRegular {
  font-family: 'MrsSaintDelafield-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "MarckScript-Regular";
  src: url('/fonts/MarckScript-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.marckScriptRegular {
  font-family: 'MarckScript-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "Arizonia-Regular";
  src: url('/fonts/Arizonia-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.arizoniaRegular {
  font-family: 'Arizonia-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "CamelliaSignature-Regular";
  src: url('/fonts/CamelliaSignature-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.camelliaSignatureRegular {
  font-family: 'CamelliaSignature-Regular';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "Amalfi Coast";
  src: url('/fonts/AmalfiCoast-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.amalfiCoastRegular {
  font-family: 'Amalfi Coast';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "Thesignature";
  src: url('/fonts/Thesignature.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.thesignature {
  font-family: 'Thesignature';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "Farmhouse";
  src: url('/fonts/Farmhouse.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.farmhouse {
  font-family: 'Farmhouse';
  font-size: 56;
  color: '#000';
}

@font-face {
  font-family: "Kingshare";
  src: url('/fonts/Kingshare.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.kingshare {
  font-family: 'Kingshare';
  font-size: 56;
  color: '#000';
}

.expired-notice {
  text-align: center;
}

.expired-notice>span {
  font-size: 1rem;
  font-weight: bold;
  color: red;
}

.expired-notice>p {
  font-size: 1.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  padding: 0 0.25rem 0 0.25rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown>p {
  margin: 0;
}

.show-counter .countdown>span {
  text-transform: uppercase;
  font-size: 1rem;
}

.custom-modal-style {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 107px;
  border-radius: 10px !important;
  background-color: transparent !important;
  border-width: 0;
  border-color: transparent !important;
}
